<template>
  <div
    id="app"
    :class="appClasses"
  >
    <!-- view (template) -->
    <transition
      name="app-view"
      mode="out-in"
      @after-leave="resetScroll"
    >
      <router-view
        class="app-view"
        v-if="app.thePost && api.ready"
        :key="app.thePost.id"
        ref="template"
      />
    </transition>

    <!-- mobile menu -->
    <transition name="mobile-menu">
      <mobile-menu
        v-if="app.mobileMenu && api.ready"
      />
    </transition>

    <!-- app bars -->
    <template v-if="$window.isAbove('m')">
      <transition name="app-bar-left">
        <app-bar-left v-if="api.ready" />
      </transition>
      <transition name="app-bar-right">
        <app-bar-right v-if="api.ready" />
      </transition>
    </template>
    <template v-else>
      <transition name="app-bar-top">
        <app-bar-top v-if="api.ready" />
      </transition>
      <app-bar-bottom v-if="api.ready" />
    </template>

    <!-- loading spinner -->
    <transition name="app-loading">
      <loading-spinner
        class="app-loading"
        v-if="!api.ready || api.loading"
      />
    </transition>
  </div>
</template>

<script>
export default {
  name: 'App',
  components: {
    'app-bar-left': () => import('@/components/app-bar-left'),
    'app-bar-right': () => import('@/components/app-bar-right'),
    'app-bar-top': () => import('@/components/app-bar-top'),
    'app-bar-bottom': () => import('@/components/app-bar-bottom'),
    'mobile-menu': () => import('@/components/mobile-menu'),
  },
  computed: {
    api(){
      return this.$api.state;
    },
    app(){
      return this.$app.state;
    },
    window(){
      return this.$window.state;
    },
    appClasses(){
      var v = this;
      return v.app.classes.concat([
        {'app-mobile-menu': v.app.mobileMenu},
        {'api-ready': v.api.ready},
        {'api-loading': v.api.loading},
        {'retina-screen': v.window.retina}
      ], [v.window.browser]);
    }
  },
  methods: {
    resetScroll(){
      window.scrollTo(0, 0);
    }
  }
}
</script>

<style lang="scss">
  // Vendors
  @import "~waffle-grid/src/waffle-grid";
  // Studio Buchanan Fonts
  @import '/assets/fonts/web-hephzibah';
  // Include global stylesheets
  @import '/assets/scss/reset';
  @import '/assets/scss/helpers';
  @import '/assets/scss/waypoints';
  @import '/assets/scss/transitions';
  @import '/assets/scss/keyframes';
  @import '/assets/scss/app';
  @import '/assets/scss/forms';
  @import '/assets/scss/range';
  @import '/assets/scss/content';
  @import '/assets/scss/masonry';
</style>
