<template>
  <a
    v-if="external && path"
    rel="external noreferrer"
    target="_blank"
    :href="path"
  >
    <slot>
      {{ text }}
    </slot>
  </a>
  <router-link
    v-else-if="path"
    :to="path"
    :title="text || ''"
    :class="{'router-link-frontpage': path === '/'}"
  >
    <slot>
      <span>
        {{ text }}
      </span>
    </slot>
  </router-link>
</template>

<script>
export default {
  props: [ 'link', ],
  computed: {
    text(){
      return _.get(this.link, 'text');
    },
    external(){
      return _.get(this.link, 'external');
    },
    path(){
      return _.get(this.link, 'path');
    }
  },
}
</script>
