<template>
  <span
    class="lottie-arrow-link"
    :class="{
      'reverse-arrow': reverse
    }"
  >
    <link-helper
      :link="link"
      @mouseenter.native="arrowMouseenter()"
      @mouseleave.native="arrowMouseleave()"
    >
      <slot v-if="!reverse" />
      <lottie
        path="/lottie/right-arrow.json"
        :autoPlay="false"
        :loop="false"
        @AnimControl="lottieArrow"
      />
      <slot v-if="reverse" />
    </link-helper>
  </span>
</template>

<script>
export default {
  props: [ 'link', 'reverse' ],
  data() {
    return {
      arrow: null,
    }
  },
  methods: {
    lottieArrow(lottie) {
      var v = this;
      v.arrow = lottie;
    },
    arrowMouseenter(){
      var v = this;
      if(v.arrow){
        v.arrow.playSegments([5, 20], true);
      }
    },
    arrowMouseleave(){
      var v = this;
      if(v.arrow){
        v.arrow.playSegments([30, 60]);
      }
    }
  },
}
</script>

<style lang="scss">
.lottie-arrow-link {
  display: inline-block;
  a {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: $black;
    font-weight: 600;
    text-transform: uppercase;
    @include fluid-type($xs, $xxl, 18px, 24px);
    .app-darkmode & {
      color: $white;
    }
  }
  .lottie {
    display: inline-block;
    width: 2.2em;
    margin-left: 0.2em;
    svg {
      display: block;
    }
  }
  &.reverse-arrow {
    .lottie {
      margin-left: 0;
      margin-right: 0.2em;
      transform: rotate(180deg);
    }
  }
}
</style>
