// https://github.com/lodash/lodash
window._ = require('lodash');

/*
* SSR
**************************************************************/
const $ssr = window.$ssr ? JSON.parse(window.$ssr) : {};

/*
* CDN
**************************************************************/
const cdn = _.get($ssr, 'options.cdn_url') || false;
if(cdn){
  cdn += cdn.endsWith('/') ? '' : '/';
  __webpack_public_path__ = cdn;
}

/*
* dependancies
**************************************************************/
// https://github.com/vuejs/vue
import Vue from 'vue'
import Render from './app'
// https://github.com/vuejs/vue-router
import Router from 'vue-router'
// https://github.com/shrpne/vue-inline-svg
import {InlineSvgPlugin} from 'vue-inline-svg';
// https://github.com/iamdustan/smoothscroll
require('smoothscroll-polyfill').polyfill();

/*
* Hextail system
**************************************************************/
import Api from './_system/api.js'
import App from './_system/app.js'
import DynamicRouter from './_system/dynamic-router.js'
import Media from './_system/media.js'
import Window from './_system/window.js'
import Utils from './_system/utils.js'
import Waypoints from './_system/waypoints.js'

/*
* config
**************************************************************/
Vue.config.productionTip = false;

/*
* debug
**************************************************************/
const domain = window.location.hostname;
const subdomain = domain.split('.');
const debug = domain === process.env.VUE_APP_LOCAL_IP || subdomain[1] === 'dev' || subdomain[1] === 'staging';
Vue.prototype.$debug = debug;
Vue.prototype.$live = !debug;
Vue.prototype.$log = function(msg, status){
  if(debug){
    var color = _.isString(status) ? '#5E35B1' : '#00D9C0';
    console.log('%c'+status, 'color:'+color, msg);
  }
};

/*
* router
**************************************************************/
Vue.use(Router)
const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: []
});

/*
* system custom plugins
**************************************************************/
Vue.use(Api);
Vue.use(App);
Vue.use(DynamicRouter);
Vue.use(Media);
Vue.use(Window);
Vue.use(Utils);
Vue.use(Waypoints);

/*
* third-party plugins
**************************************************************/
Vue.use(InlineSvgPlugin);

/*
* base components
**************************************************************/
const requireComponent = require.context('./components/base', true, /\.vue/);
requireComponent.keys().forEach(fileName => {
  const componentConfig = requireComponent(fileName);
  const componentName = fileName.split('/').pop().replace(/\.\w+$/, '');
  Vue.component(componentName, componentConfig.default || componentConfig);
});

/*
* app
**************************************************************/
const v = new Vue({
  router,
  render: h => h(Render),
  created(){
    this.$app.init(this);
  }
}).$mount('#app')
router.beforeEach((to, from, next) => {
  v.$dynamicRouter.changeRoute(to, from, next, v);
});

/*
* Analytics
**************************************************************/
const fathomID = _.get($ssr, 'options.fathom_site_id');
if(fathomID){
  const fathomScript = document.createElement('script');
  fathomScript.onload = () => {
    if(window.fathom){
      // analytics init
      v.$log({url: window.location.pathname}, 'Analytics');
      if(!debug){
        window.fathom.trackPageview();
      }
      // analytics changes
      router.afterEach((to, from, failure) => {
        const url = _.get(to, 'fullPath', undefined);
        const referrer = _.get(from, 'fullPath', undefined);
        if(!failure && url !== referrer){
          if(!debug){
            window.fathom.trackPageview({url, referrer});
          }
          v.$log({url, referrer}, 'Analytics');
        }
      });
    }
  };
  // analytics config
  fathomScript.setAttribute('data-site', fathomID);
  fathomScript.setAttribute('data-auto', false);
  fathomScript.setAttribute('defer', '');
  fathomScript.src = 'https://cdn.usefathom.com/script.js';
  document.head.appendChild(fathomScript);
}

/*
* ASCII
**************************************************************/
if(!debug){
  console.log(`
        
 ██                                   ██
 ██                                   ██
 ██████   ████  ██  ███████  ████  ██ ██
 ██   ██ ██  ██  ████   ██  ██  ██ ██ ██
 ██   ██ █████  ██  ██  ███  █████ ██ ██ ®

  -------- https://hextail.com --------

  `);
}
