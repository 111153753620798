<template>
  <div
    class="lottie"
    ref="container"
  />
</template>

<script>
import lottie from 'lottie-web'
export default {
  props: [ 'path', 'speed', 'autoplay', 'loop', 'renderer' ],
  data() {
    return {
      animation: null,
    }
  },
  mounted() {
    var v = this;
    v.init();
  },
  beforeDestroy(){
    var v = this;
    setTimeout(() => {
      if(v.animation) {
        v.$log('Destroyed', 'Lottie');
        v.animation.destroy();
      }
    }, v.$app.viewLeaveDuration);
  },
  watch: {
    path(){
      this.init();
    }
  },
  methods: {
    init(){
      var v = this;
      if(v.animation) {
        v.animation.destroy();
        v.animation = null;
      }
      v.animation = lottie.loadAnimation({
        container: v.$refs.container,
        loop: v.loop || false,
        autoplay: v.autoplay || false,
        path: v.path || '',
        resizeMode: 'cover',
        renderer: v.renderer || 'svg'
      });
      v.$emit('AnimControl', v.animation);
      v.animation.addEventListener('data_ready', () => {
        v.$emit('loaded', true);
      });
    }
  }
}
</script>

<style lang="scss">
.lottie {
  width: 100%;
  height: 100%;
  overflow: hidden;
  margin: 0 auto;
  svg {
    display: block;
  }
}
</style>
