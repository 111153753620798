export default {
  install(Vue) {

    /*
    * app store
    **************************************************************/
    Vue.prototype.$app = {
      state: new Vue({data(){ return {
        darkmode: false,
        mobileMenu: false,
        footerInView: false,
        thePost: false,
        mounted: false,
        classes: [],
        activeSelect: null
      }}}),
      viewLeaveDuration: 200,
      version: process.env.VUE_APP_VERSION,
      env: process.env.NODE_ENV,

      // typeface categories
      typeCategories: [
        {label: 'Sans Serif', value: 'sans'},
        {label: 'Display',  value: 'display'},
        {label: 'Serif', value: 'serif'},
        {label: 'Text', value: 'text'},
        {label: 'Variable', value: 'variable'},
      ],

      // casestudy categories
      caseCategories: [
        {label: 'Custom type', value: 'custom_type'},
        {label: 'Branding',  value: 'branding'},
        {label: 'Animation', value: 'animation'},
      ],

      // initialise - very first function - only ran once
      init(v){
        var $app = this;
        v.$api.init(v);
        v.$window.init(v);
        v.$media.init(v);
        if(localStorage.darkmode === 'on'){
          $app.enableDarkMode();
        }
        document.addEventListener('click', (e) => {
          $app.selectCheck(e);
        });
      },

      // mobile menu helpers
      toggleMobileMenu(){
        this.state.mobileMenu = !this.state.mobileMenu;
      },
      openMobileMenu(){
        this.state.mobileMenu = true;
      },
      closeMobileMenu(){
        this.state.mobileMenu = false;
      },

      // dark mode helpers
      toggleDarkMode(v){
        this.state.darkmode = !this.state.darkmode;
        if(this.state.darkmode){
          localStorage.darkmode = 'on';
          document.documentElement.classList.add('app-darkmode');
          document.body.classList.add('app-darkmode');
        } else {
          localStorage.darkmode = '';
          document.documentElement.classList.remove('app-darkmode');
          document.body.classList.remove('app-darkmode');
        }
      },
      enableDarkMode(){
        this.state.darkmode = 'on';
        document.documentElement.classList.add('app-darkmode');
        document.body.classList.add('app-darkmode');
      },
      disableDarkMode(){
        this.state.darkmode = '';
        document.documentElement.classList.remove('app-darkmode');
        document.body.classList.remove('app-darkmode');
      },
      darkLottie(filename){
        return '/lottie/'+filename+(this.state.darkmode ? '_darkmode' : '')+'.json';
      },

      // footer in view
      footerInView(waypoint, v){
        v.$app.state.footerInView = waypoint.active;
      },
      footerNotInView(v){
        v.$app.state.footerInView = false;
      },

      // clear the current post
      clearThePost(){
        this.state.thePost = false;
      },

      // get page title
      pageTitle(v, post){
        var title = '';
        var siteTitle = v.$api.state.options.site_title;
        if(post.permalink.frontpage){
          title = post.seo_title ? post.seo_title : siteTitle;
        } else {
          title = post.seo_title ? post.seo_title : post.title;
          title = title+' | '+siteTitle;
        }
        return title;
      },

      // set the current post
      setThePost(v, post){
        // set the post
        this.state.thePost = post;

        // document title
        var title = this.pageTitle(v, post);
        document.title = title;

        // document desc
        var desc = post.seo_desc || v.$api.state.options.site_desc || '';
        var description = document.head.querySelector('meta[name="description"]');
        description.setAttribute('content', desc);

        // document canonical
        var url = _.trimEnd(v.$api.state.options.site_url || window.location.origin, '/');
        var canonical = document.head.querySelector('link[rel="canonical"]');
        canonical.setAttribute('href', url+post.permalink.path);

        // robots
        var indexing = post.seo_no_index || v.$debug ? 'noindex, nofollow' : 'index, follow';
        var robots = document.head.querySelector('meta[name="robots"]');
        robots.setAttribute('content', indexing);

        // update template styles
        this.setClasses(post);
      },

      // set classes
      setClasses(post){
        // defaults
        var classes = [
          'post-type-'+post.post_type,
          'template-'+post.template.replace('_', '-')
        ];
        this.state.classes = classes;
      },

      // post mounted
      setMounted(v){
        this.state.mounted = true;
        v.$nextTick(() => {
          v.$waypoints.update(v);
        });
      },
      clearMounted(){
        this.state.mounted = false;
      },

      // select
      selectCheck(e){
        if(!e.target.closest('.input-select')) {
          this.closeSelect();
        }
      },
      openSelect(id){
        this.state.activeSelect = id;
      },
      closeSelect(){
        this.state.activeSelect = null;
      },
    };

  }
};
