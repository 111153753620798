<template>
  <div
    class="input-select"
    :class="{
      'open': isOpen,
      'multi': multi,
      'bordered': bordered
    }"
  >
    <div
      class="selected"
      @click.prevent="dropdown"
    >
      <span :class="{'show-selected': selected}">
        {{ label }} <strong v-if="selected">{{ selected }}</strong>
        <svg v-if="isOpen" key="opened" aria-hidden="true" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M288.662 352H31.338c-17.818 0-26.741-21.543-14.142-34.142l128.662-128.662c7.81-7.81 20.474-7.81 28.284 0l128.662 128.662c12.6 12.599 3.676 34.142-14.142 34.142z"></path></svg>
        <svg v-else key="unopened" aria-hidden="true" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z"></path></svg>
      </span>
    </div>
    <div 
      class="options"
      :style="{
        right: right ? '0' : 'auto'
      }"
    >
      <transition name="slide-up-down">
        <div
          class="inner"
          v-if="isOpen"
          :style="{
            'grid-template-columns': 'repeat('+optionColumns+', min-content)'
          }"
        >
          <a
            href="#"
            v-for="option in options"
            :key="option.value"
            @click.prevent="setOption(option.value)"
            :class="{'active': activeOption(option.value)}"
          >
            <span /> {{ option.label }}
          </a>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  props: [ 'placeholder', 'value', 'options', 'multi', 'show', 'cols', 'right', 'bordered' ],
  data(){
    return {
      open: false
    }
  },
  computed: {
    app(){
      return this.$app.state;
    },
    isOpen(){
      return this.app.activeSelect === this._uid;
    },
    label(){
      return this.placeholder || 'Select';
    },
    selected(){
      var v = this;
      if(v.show && !v.multi){
        return _.get(_.find(v.options, ['value', v.value]), 'label');
      }
      return false;
    },
    optionColumns(){
      var v = this;
      var max = 5;
      if(v.$window.isBelow('xxs')){
        max = 1;
      } else if(v.cols){
        max = v.cols;
      } else if(v.$window.isBelow('s')){
        max = 2;
      } else if(v.$window.isBelow('m')){
        max = 3;
      } else if(v.$window.isBelow('xl')){
        max = 4;
      }
      return v.options.length > max ? max : v.options.length;
    }
  },
  methods: {
    activeOption(value){
      var v = this;
      if(v.multi){
        return _.includes(v.value, value);
      } else {
        return v.value === value;
      }
    },
    dropdown(){
      var v = this;
      if(v.isOpen){
        v.$app.closeSelect();
      } else {
        v.$app.openSelect(v._uid);
      }
    },
    setOption(value, init){
      var v = this;
      var selected = v.value;
      if(v.multi){
        if(_.includes(selected, value)){
          v.$delete(selected, _.indexOf(selected, value));
        } else {
          selected = _.union(selected, _.isArray(value) ? value : [value]);
        }
      } else {
        if(selected === value) {
          return;
        }
        selected = value;
      }
      if(!init){
        v.$emit('input', selected);
        // setTimeout(() => {
        //   v.$app.closeSelect();
        // }, 100);
      }
    }
  }
}
</script>

<style lang="scss">
.input-select {
  position: relative;
  z-index: 2;
  @include fluid-type($xxs, $xxl, 14px, 16px);
  letter-spacing: 0.02em;
  user-select: none;
  text-transform: uppercase;
  .selected {
    position: relative;
    z-index: 2;
    padding: 0.5em 1em;
    cursor: pointer;
    @include center-vertical();
    &:before {
      content: '';
      position: absolute;
      @include fill();
      background-color: $black;
      border-radius: 20px;
      opacity: 0;
      transform: scale(0.8);
      transition: all .2s $bounce-out;
      .app-darkmode & {
        background-color: $white;
      }
    }
    * {
      pointer-events: none;
    }
    span {
      display: block;
      position: relative;
      font-weight: 600;
      padding-right: 1em;
      &.show-selected {
        font-weight: 400;
        strong {
          font-weight: 600;
        }
      }
    }
    svg {
      width: 0.5em;
      display: block;
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      right: 0;
      fill: $black;
      .app-darkmode & {
        fill: $white;
      }
    }
  }
  .options {
    position: absolute;
    top: 100%;
    pointer-events: none;
    .inner {
      padding: 0.5em 1.2em;
      background-color: $black;
      border-radius: 20px;
      max-width: $inner-width;
      pointer-events: all;
      display: inline-grid;
      grid-gap: 0 1em;
      .app-darkmode & {
        background-color: $white;
      }
    }
    a {
      display: block;
      position: relative;
      padding: 0.7em 0.9em 0.7em 30px;
      font-weight: 500;
      font-size: 0.9em;
      line-height: 20px;
      color: $white;
      transition: all .2s;
      white-space: nowrap;
      .app-darkmode & {
        color: $black;
      }
      &:hover {
        opacity: 0.5;
      }
      span {
        display: block;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 2px solid $white;
        position: absolute;
        top: 0.7em;
        left: 0;
        .app-darkmode & {
          border: 2px solid $black;
        }
        &:before {
          content: '';
          display: block;
          position: absolute;
          left: 4px;
          top: 4px;
          width: 8px;
          height: 8px;
          background: $white;
          border-radius: 50%;
          opacity: 0;
          transform: scale(0);
          transition: all .2s;
          .app-darkmode & {
            background: $black;
          }
        }
      }
      &.active {
        span {
          &:before {
            opacity: 1;
            transform: scale(1);
          }
        }
        &:hover {
          opacity: 1;
        }
      }
    }
  }
  &.open {
    .selected {
      span {
        color: $white;
        .app-darkmode & {
          color: $black;
        }
      }
      &:before {
        opacity: 1;
        transform: scale(1);
      }
      svg {
        fill: $white;
        .app-darkmode & {
          fill: $black;
        }
      }
    }
  }
  &.multi {
    .options {
      a {
        span {
          border-radius: 4px;
          &:before {
            border-radius: 2px;
          }
        }
      }
    }
  }
  &.bordered {
    .selected {
      span {
        color: $black;
        .app-darkmode & {
          color: $white;
        }
      }
      svg {
        fill: $black;
        .app-darkmode & {
          fill: $white;
        }
      }
      &:before {
        border: 3px solid $black;
        background-color: $white;
        .app-darkmode & {
          border: 3px solid $white;
          background-color: $black;
        }
      }
    }
    .options {
      .inner {
        border: 3px solid $black;
        background-color: $white;
        .app-darkmode & {
          border: 3px solid $white;
          background-color: $black;
        }
        a {
          color: $black;
          font-weight: 700;
          font-size: 1em;
          padding: 0.5em 0.9em 0.5em 30px;
          .app-darkmode & {
            color: $white;
          }
          span {
            border: 3px solid $black;
            top: 0.5em;
            .app-darkmode & {
              border: 3px solid $white;
            }
            &:before {
              left: 3px;
              top: 3px;
              background: $black;
              .app-darkmode & {
                background: $white;
              }
            }
          }
        }
      }
    }
  }
}
</style>
