<template>
  <div
    class="input-switch"
    :class="{'active': active}"
  >
    <a
      href="#"
      @click.prevent="setSwitch()"
      aria-label="Switch toggle"
    >
      <div class="dot" />
    </a>
  </div>
</template>

<script>
export default {
  props: [ 'default' ],
  data(){
    return {
      active: false
    }
  },
  created(){
    var v = this;
    v.active = v.default;
  },
  methods: {
    setSwitch(){
      var v = this;
      v.active = v.active = !v.active;
      v.$emit('change', v.active);
    }
  }
}
</script>

<style lang="scss">
.input-switch {
  a {
    background-color: $white;
    border: 3px solid $black;
    display: block;
    width: 60px;
    height: 34px;
    border-radius: 30px;
    position: relative;
    transition: all .3s;
    .dot {
      position: absolute;
      top: 3px;
      left: 4px;
      width: 22px;
      height: 22px;
      background-color: $black;
      border-radius: 50%;
      transition: all .3s $ease-out-in;
    }
  }
  &.active {
    a {
      background-color: $black;
      border-color: $white;
      .dot {
        transform: translate(25px, 0);
        background-color: $white;
      }
    }
  }
}
</style>
