<template>
  <div class="loading-spinner">
    <svg viewBox="0 0 50 50">
      <circle cx="25" cy="25" r="22"></circle>
    </svg>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss">
.loading-spinner {
  display: block;
  svg {
    circle {
      fill: transparent;
      stroke: $black;
      stroke-width: 5;
      stroke-linecap: round;
      stroke-dasharray: 120;
      transform-origin: 50% 50% 0;
      animation: loading-spinner 1.3s linear infinite;
    }
  }
}
</style>
